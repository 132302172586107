import type { ComputedRef } from 'vue'
import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
import { SessionStore } from '~/stores/session'
import { EngineStore } from '~/stores/engine'

export default class CarrierAepExceptions {
  static async use(carrierKey: string, carrierName: string) {
    const { flag } = FeatureFlags.use()

    const carrierAepExceptions = computed(() => flag<string[]>('carrier-aep-exceptions').value)

    const { getComponentContent, hydratePlaceholders } = Cms.use()

    const { content: aepNotice } = await getComponentContent('CarrierAepExceptionNotice')

    const aepException = computed(() => carrierAepExceptions.value.includes(carrierKey))

    const aepNoticeText = computed(() =>
      hydratePlaceholders(aepNotice.value.ext?.notice, {
        carrierName: carrierName
      })
    )

    return {
      carrierAepExceptions,
      aepException,
      aepNoticeText
    }
  }

  static async useCompare() {
    const { flag } = FeatureFlags.use()

    const carrierAepExceptions = computed(() => flag<string[]>('carrier-aep-exceptions').value)

    const { getComponentContent, hydratePlaceholders } = Cms.use()

    const { content: aepNotice } = await getComponentContent('CarrierAepExceptionNotice')

    const session = SessionStore.use()
    const engine = EngineStore.use()

    const aepExceptions = computed(() =>
      session.comparedPlans.map((p) => {
        const plan = engine.quotesCatalog[p]
        const excepted = carrierAepExceptions.value.includes(plan.details.carrierKey!)
        const text = hydratePlaceholders(aepNotice.value.ext?.notice, {
          carrierName: plan.details.carrierName
        })

        return {
          medicareId: plan.medicareId,
          aepException: excepted,
          notice: text
        }
      })
    )

    return {
      aepExceptions
    }
  }
}
